import axios from 'axios';
import { ActionTypes } from '../../utils/constants';
import { MARKETPLACE_API_URL } from '../../config';
import { message } from 'antd';

/* Premint NFT to IPFS and DB */
export const premintNFT = data => {
  const url = `${MARKETPLACE_API_URL}/nft`;

  return async dispatch => {
    dispatch(premintNFTsRequest());
    try {
      const res = await axios.post(url, data);
      return dispatch(premintNFTsSuccess(res));
    } catch (err) {
      return dispatch(premintNFTsFailure(err.response));
    }
  };
};
const premintNFTsRequest = () => {
  return {
    type: ActionTypes.PREMINT_NFTS_REQUEST,
  };
};
const premintNFTsSuccess = payload => {
  return {
    type: ActionTypes.PREMINT_NFTS_SUCCESS,
    payload,
  };
};
const premintNFTsFailure = payload => {
  return {
    type: ActionTypes.PREMINT_NFTS_FAILURE,
    payload,
  };
};

/* Edit NFT from DB*/
export const editPremintNFT = (editionsBundleId, contractTokenId, data) => {
  const url = `${MARKETPLACE_API_URL}/nft/${editionsBundleId}?contractTokenId=${contractTokenId}`;

  return async dispatch => {
    dispatch(editPremintNFTRequest());
    try {
      const res = await axios.put(url, data);
      return dispatch(editPremintNFTSuccess(res));
    } catch (err) {
      return dispatch(editPremintNFTFailure(err.response));
    }
  };
};
const editPremintNFTRequest = () => {
  return {
    type: ActionTypes.EDIT_PREMINT_NFT_REQUEST,
  };
};
const editPremintNFTSuccess = payload => {
  return {
    type: ActionTypes.EDIT_PREMINT_NFT_SUCCESS,
    payload,
  };
};
const editPremintNFTFailure = payload => {
  return {
    type: ActionTypes.EDIT_PREMINT_NFT_FAILURE,
    payload,
  };
};

/* Remove preminted NFT from IPFS and DB*/
export const removePremintNFT = data => {
  const url = `${MARKETPLACE_API_URL}/nft/${data.editionsBundleId}`;

  return async dispatch => {
    dispatch(removePremintNFTsRequest());
    try {
      const res = await axios.delete(url);

      return dispatch(removePremintNFTsSuccess(res));
    } catch (err) {
      return dispatch(removePremintNFTsFailure(err.response));
    }
  };
};
const removePremintNFTsRequest = () => {
  return {
    type: ActionTypes.REMOVE_PREMINT_NFTS_REQUEST,
  };
};
const removePremintNFTsSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_PREMINT_NFTS_SUCCESS,
    payload,
  };
};
const removePremintNFTsFailure = payload => {
  return {
    type: ActionTypes.REMOVE_PREMINT_NFTS_FAILURE,
    payload,
  };
};

/* Get a preminted NFT from DB */
export const getPremintedNFT = editionsBundleId => {
  return async dispatch => {
    dispatch(getPremintedNFTRequest());
    try {
      const { data } = await axios.get(`${MARKETPLACE_API_URL}/nft/${editionsBundleId}`);
      return dispatch(getPremintedNFTSuccess(data));
    } catch ({ message }) {
      return dispatch(getPremintedNFTFailure());
    }
  };
};
const getPremintedNFTRequest = () => {
  return {
    type: ActionTypes.GET_PREMINTED_NFT_REQUEST,
  };
};
const getPremintedNFTSuccess = payload => {
  return {
    type: ActionTypes.GET_PREMINTED_NFT_SUCCESS,
    payload,
  };
};
const getPremintedNFTFailure = () => {
  return {
    type: ActionTypes.GET_PREMINTED_NFT_FAILURE,
  };
};

/* Get all premints grouped by bundleId NFTs from DB */
export const getNFTs = () => {
  return async dispatch => {
    dispatch(getNFTsRequest());
    try {
      const { data } = await axios.get(`${MARKETPLACE_API_URL}/nfts`);
      return dispatch(getNFTsSuccess(data));
    } catch (err) {
      message.error(`${err.response}`, 2);
      return dispatch(getNFTsFailure());
    }
  };
};

const getNFTsRequest = () => {
  return {
    type: ActionTypes.GET_NFTS_REQUEST,
  };
};
const getNFTsSuccess = payload => {
  return {
    type: ActionTypes.GET_NFTS_SUCCESS,
    payload,
  };
};
const getNFTsFailure = () => {
  return {
    type: ActionTypes.GET_NFTS_FAILURE,
  };
};

/* Get filtered  NFTs */
let filteredNFTs;
export const getFilteredNFT = ({ name, IpfsHash }, data) => {
  // Return NFT's filtered by name and/or token
  let filterByName = name && data.filter(el => el.name.toLowerCase().includes(name.toLowerCase()));
  let filterByToken = IpfsHash && data.filter(el => el.IpfsHash.toString().includes(IpfsHash));

  return async dispatch => {
    name && IpfsHash
      ? (filteredNFTs = filterByName.concat(filterByToken))
      : name && !IpfsHash
      ? (filteredNFTs = filterByName)
      : !name && IpfsHash
      ? (filteredNFTs = filterByToken)
      : (filteredNFTs = data);
    return dispatch({
      type: ActionTypes.GET_FILTERED_NFT,
      payload: filteredNFTs,
    });
  };
};

/* Approve the market contract */
export const approveMarketContract = (tokenID, address, contract, marketContract) => {
  return async dispatch => {
    dispatch(approveMarketContractRequest());
    try {
      await contract.methods.approve(marketContract.options.address, tokenID).send({ from: address });

      return dispatch(approveMarketContractSuccess());
    } catch ({ message }) {
      return dispatch(approveMarketContractFailure());
    }
  };
};
const approveMarketContractRequest = () => {
  return {
    type: ActionTypes.APPROVE_MARKET_CONTRACT_REQUEST,
  };
};
const approveMarketContractSuccess = payload => {
  return {
    type: ActionTypes.APPROVE_MARKET_CONTRACT_SUCCESS,
    payload,
  };
};
const approveMarketContractFailure = () => {
  return {
    type: ActionTypes.APPROVE_MARKET_CONTRACT_FAILURE,
  };
};

/* List a NFT */
export const listNFT = (tokenID, startingPrice, endDate, address, contract, marketContract, web3) => {
  return async dispatch => {
    dispatch(listNFTRequest());
    try {
      await marketContract.methods
        .createListing(contract.options.address, tokenID, endDate, web3.utils.toWei(startingPrice, 'ether'))
        .send({ from: address });

      return dispatch(listNFTSuccess());
    } catch ({ message }) {
      console.error(message);
      return dispatch(listNFTFailure());
    }
  };
};
const listNFTRequest = () => {
  return {
    type: ActionTypes.LIST_NFT_REQUEST,
  };
};
const listNFTSuccess = payload => {
  return {
    type: ActionTypes.LIST_NFT_SUCCESS,
    payload,
  };
};
const listNFTFailure = () => {
  return {
    type: ActionTypes.LIST_NFT_FAILURE,
  };
};

/* Set wallet address */
export const setWalletAddress = address => {
  return {
    type: ActionTypes.SET_WALLET_ADDRESS,
    payload: address,
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};

/* Ungroup bundle*/
export const ungroupBundle = data => {
  const url = `${MARKETPLACE_API_URL}/ungroup-bundle/${data.editionsBundleId}`;

  return async dispatch => {
    dispatch(ungroupBundleRequest());
    try {
      const res = await axios.put(url);
      return dispatch(ungroupBundleSuccess(res));
    } catch (err) {
      return dispatch(ungroupBundleFailure(err.response));
    }
  };
};
const ungroupBundleRequest = () => {
  return {
    type: ActionTypes.UNGROUP_BUNDLE_REQUEST,
  };
};
const ungroupBundleSuccess = payload => {
  return {
    type: ActionTypes.UNGROUP_BUNDLE_SUCCESS,
    payload,
  };
};
const ungroupBundleFailure = payload => {
  return {
    type: ActionTypes.UNGROUP_BUNDLE_FAILURE,
    payload,
  };
};

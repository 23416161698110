import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, message, Button, Tag, Popconfirm, Drawer, Card, Modal, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { getNFTs, removePremintNFT, getPremintedNFT, cleanStore, ungroupBundle } from '../../../redux/actions/nfts';
import { useBackoffice } from '../../../context/BackofficeContext';
import Web3 from 'web3';
import marketplaceContractABI from '../../../services/web3/abis/Marketplace.json';
import { MARKETPLACE_CONTRACT_ADDRESS, NETWORK_SCAN } from '../../../config/index';
import { firstCapitaLetter } from '../../../utils/helpers';
import { useOnboardContext, useAddress } from '../../../context/OnboardContext';
import { NFTMarket, NFTStatus } from '../../../utils/status';
import moment from 'moment';

import { MintNFT, Listing } from '../../index';
import ModalTransactionSteps from '../modalTransactionSteps/ModalTransactionSteps';
import { isAvailableForListing } from '../../../services/nftServices';
import { useNFT } from '../../../context/nft.context';
import { NFTModalState, NFTTableDrawerState } from '../../../utils/nft.data';
import { getCollections } from '../../../redux/actions/collections';

const { Column } = Table;

const TableNFTContent = () => {
  const { setRightPanel, showModal, setListingAgainData, rightPanel, modal, stepsModalVisible } = useNFT();

  const dispatch = useDispatch();
  const web3 = new Web3(Web3.givenProvider);
  const { linkWallet } = useOnboardContext();
  const walletAddress = useAddress();
  const { setTooltips } = useBackoffice();

  const { NFTs, filteredNFT, loadingRemoveNFT, removedNFT, premintedNFT, loadingUngroupBundle, ungroupedBundle } =
    useSelector(({ nfts }) => nfts);
  useEffect(() => {
    dispatch(getNFTs());
    dispatch(getCollections());
    dispatch(cleanStore());
    setTooltips(false);
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    loadingRemoveNFT && message.loading('Removing NFT...', 0.5);

    removedNFT?.status === 200 &&
      dispatch(getNFTs()).then(() => {
        message.success(`${removedNFT?.data.message}`, 2);
      });

    removedNFT?.status === 404 && message.error(`${removedNFT?.data?.message}`, 2);

    dispatch(cleanStore());

    /* eslint-disable */
  }, [loadingRemoveNFT, removedNFT]);
  /* eslint-enable */

  useEffect(() => {
    loadingUngroupBundle && message.loading('Ungrouping bundle...', 0.5);

    ungroupedBundle?.status === 200 &&
      dispatch(getNFTs()).then(() => {
        message.success(`${ungroupedBundle?.data.message}`, 2);
      });

    ungroupedBundle?.status === 404 && message.error(`${ungroupedBundle?.data?.message}`, 2);

    dispatch(cleanStore());

    /* eslint-disable */
  }, [loadingUngroupBundle, ungroupedBundle]);
  /* eslint-enable */

  const handleRemoveNFT = record => {
    dispatch(removePremintNFT(record));
  };

  const handleUngroupBundle = record => {
    dispatch(ungroupBundle(record));
  };

  const handleOpenDrawer = async (record, type) => {
    await dispatch(getPremintedNFT(record?.editionsBundleId));
    setListingAgainData();
    setRightPanel(type);
  };
  const handleCloseDrawer = () => {
    setRightPanel();
  };

  const handleAction = (action, record) => {
    dispatch(getPremintedNFT(record?.editionsBundleId));
    action === NFTModalState.LIST_AGAIN && setListingAgainData(record);
    showModal(action);
  };

  const wrapAuction = async record => {
    if (!walletAddress) {
      await linkWallet();
    }

    if (walletAddress) {
      const marketplaceContract = new web3.eth.Contract(marketplaceContractABI, MARKETPLACE_CONTRACT_ADDRESS);

      await marketplaceContract.methods
        .finalizeListing(record.listings[0]?.contractListingID)
        .send({
          from: walletAddress,
        })
        .once('receipt', function (receipt) {
          message.success('The auction was wrapped successfully', 3);
        })
        .on('error', function (error, receipt) {
          message.error(error.message, 3.5);
          return;
        });
    }
  };

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <h2 style={{ margin: '0' }}>List of available NFTs</h2>

        <Link to="/premint" style={{ marginLeft: 'auto' }}>
          <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Premint a new NFT
          </Button>
        </Link>
      </div>
    );
  }

  function showTotal(total) {
    return `Total ${total} items`;
  }

  return (
    <>
      <Table
        dataSource={filteredNFT?.length > 0 ? filteredNFT : NFTs}
        style={{ margin: 15, maxHeight: '500px' }}
        size="middle"
        rowKey="_id"
        title={() => header()}
        loading={NFTs ? false : true}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30'],
          total: filteredNFT?.length > 0 ? filteredNFT.length : NFTs.length,
          showTotal: showTotal,
        }}
      >
        <Column
          title="NFT Name"
          dataIndex="name"
          key="name"
          width="14%"
          ellipsis={true}
          render={(text, record) => (
            <Button
              type="link"
              onClick={() => handleOpenDrawer(record, NFTTableDrawerState.NFT)}
              style={{ padding: '0' }}
            >
              {text}
            </Button>
          )}
        />
        <Column
          title="Collection"
          dataIndex="collectionRef"
          key="collectionRef"
          width="14%"
          ellipsis={true}
          render={(text, record) => <span>{text?.name}</span>}
        />

        <Column title="Description" dataIndex="description" key="description" ellipsis={true} />

        <Column
          title="Editions"
          dataIndex="edition"
          key="edition"
          width="10%"
          render={(text, record) => (
            <Button
              type="link"
              onClick={() => handleOpenDrawer(record, NFTTableDrawerState.EDITIONS)}
              style={{ padding: '0' }}
            >
              {text?.total} {text?.total === 1 ? 'edition.' : 'editions.'}
            </Button>
          )}
        />

        <Column
          title="Date of Creation"
          dataIndex="creationDate"
          key="date"
          defaultSortOrder="descend"
          width="15%"
          sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
          render={date => <>{date && moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
        />

        <Column
          title="Action"
          key="actions"
          width="20%"
          dataIndex="actions"
          render={(actions, record) => {
            return (
              <span>
                {record?.status[0] === 'preminted' || record?.status[0] === 'minting' ? (
                  <>
                    <Link to={'/edit/nft/' + record.editionsBundleId} style={{ marginRight: '10px' }}>
                      Edit
                    </Link>
                    <Button
                      type="link"
                      onClick={() => handleAction(NFTModalState.MINT, record)}
                      style={{ margin: '0 10px 0 0', padding: '0' }}
                    >
                      Mint
                    </Button>

                    <Popconfirm
                      title="Are you sure to delete this NFT?"
                      onConfirm={() => handleRemoveNFT(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                        Remove
                      </Button>
                    </Popconfirm>
                  </>
                ) : ![NFTStatus.PREMINTED, NFTStatus.MINTING].includes(record?.status) &&
                  isAvailableForListing(record?.status) ? (
                  <Button type="link" onClick={() => handleAction(NFTModalState.LIST, record)} style={{ padding: '0' }}>
                    List
                  </Button>
                ) : null}

                {record?.status[0] === 'listed' && record.edition.total > 1 && (
                  <>
                    <Popconfirm
                      title="Are you sure to ungroup this bundle?"
                      onConfirm={() => handleUngroupBundle(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                        Ungroup
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </span>
            );
          }}
        />
      </Table>
      <Drawer
        title={premintedNFT && `Editions of ${premintedNFT[0]?.name}`}
        placement="right"
        onClose={handleCloseDrawer}
        visible={rightPanel === NFTTableDrawerState.EDITIONS}
        width={'60%'}
      >
        {premintedNFT && (
          <Table
            dataSource={premintedNFT && premintedNFT}
            style={{ margin: 15 }}
            size="small"
            rowKey="_id"
            pagination={false}
          >
            <Column
              title="Token ID"
              dataIndex="contractTokenId"
              key="contractTokenId"
              ellipsis={true}
              render={(text, record) => (
                <>
                  {text ? (
                    <a
                      href={`${NETWORK_SCAN}token/${record?.collectionRef?.contractAddress}?a=${text}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Token: {text}
                    </a>
                  ) : (
                    'No contract token ID'
                  )}
                </>
              )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              ellipsis={true}
              render={(text, record) => (
                <Tag
                  color={
                    text === 'preminted'
                      ? '#D9D9D9'
                      : text === 'minted'
                      ? '#1890ff'
                      : text === 'approved'
                      ? '#faad14'
                      : text === 'listed'
                      ? '#52C41A'
                      : text === 'sold'
                      ? '#FF4D4F'
                      : text === 'finalized'
                      ? '#bfbfbf'
                      : text === 'minting' || text === 'approving' || text === 'listing'
                      ? '#ffc53d'
                      : null
                  }
                  key={record?.contractTokenId}
                >
                  {text?.toUpperCase()}
                </Tag>
              )}
            />
            <Column
              title="Listed in"
              dataIndex="listings"
              key="market"
              width="15%"
              ellipsis={true}
              render={(market, record) => (
                <>
                  {record.listings[0]?.market === NFTMarket.PRIMARY_SALE
                    ? `Primary Sale`
                    : record.listings[0]?.market === NFTMarket.SECONDARY_SALE
                    ? `Secondary Sale`
                    : '---'}
                </>
              )}
            />
            <Column
              title="Listing type"
              dataIndex="listings"
              key="listings"
              width="20%"
              ellipsis={true}
              render={(listings, record) => (
                <>
                  {record.listings[0]?.type
                    ? record.listings[0]?.type === 'fixed_price'
                      ? `ID: ${record.listings[0]?.contractListingID} - Fixed price`
                      : `ID: ${record.listings[0]?.contractListingID} - English auction - ${record.listings[0]?.bids.length} bids.`
                    : 'Not listed yet'}{' '}
                </>
              )}
            />
            <Column
              title="Finalize in"
              dataIndex="endDate"
              key="date"
              render={(date, record) => (
                <>
                  {record.listings[0]?.type === 'english_auction'
                    ? record.listings[0]?.endDate && firstCapitaLetter(moment(record.listings[0]?.endDate).fromNow())
                    : record.listings[0]?.type === 'fixed_price'
                    ? '∞'
                    : '---'}
                </>
              )}
            />

            <Column
              title="Action"
              key="actions"
              width="20%"
              dataIndex="actions"
              render={(actions, record) => (
                <span>
                  {record?.listings[0]?.type === 'english_auction' &&
                    record.status === 'finalized' &&
                    record.listings[0]?.bids?.length > 0 && (
                      <Popconfirm
                        title="This action will transfer the NFT to the winner of the auction"
                        onConfirm={() => wrapAuction(record)}
                        okText="Yes, transfer"
                        cancelText="No, cancel"
                      >
                        <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                          Wrap auction
                        </Button>
                      </Popconfirm>
                    )}
                  {record?.listings[0]?.type === 'english_auction' &&
                    record.status === 'finalized' &&
                    record.listings[0]?.bids?.length === 0 && (
                      <Tooltip title="No bids were made for this auction">
                        <Button
                          type="link"
                          style={{ margin: '0 10px 0 0', padding: '0' }}
                          onClick={() => {
                            handleAction(NFTModalState.LIST_AGAIN, record);
                          }}
                        >
                          List for sale again
                        </Button>
                      </Tooltip>
                    )}
                </span>
              )}
            />
          </Table>
        )}
      </Drawer>
      <Drawer
        title={premintedNFT && premintedNFT[0]?.name}
        placement="right"
        onClose={handleCloseDrawer}
        visible={rightPanel === NFTTableDrawerState.NFT}
        width={500}
      >
        {premintedNFT && (
          <Card
            style={{ width: 450 }}
            cover={
              <img
                src={
                  premintedNFT[0]?.imgCDNURL.includes('https://')
                    ? premintedNFT[0]?.imgCDNURL
                    : `https://ipfs.io/ipfs/${premintedNFT[0]?.imgCDNURL}`
                }
                alt="NFT img"
                style={{ maxWidth: 450, maxHeight: 250, objectFit: 'contain' }}
              />
            }
          >
            <Card.Meta
              title={`${premintedNFT && premintedNFT[0]?.name} - ${
                premintedNFT && premintedNFT[0]?.collectionRef?.name
              }`}
              description={premintedNFT && premintedNFT[0]?.description}
            />
          </Card>
        )}
      </Drawer>
      <Modal
        title={premintedNFT && `${modal} ${premintedNFT[0]?.name}`}
        visible={!stepsModalVisible && modal}
        width={'auto'}
        onCancel={() => {
          showModal(false);
          dispatch(cleanStore());
          handleCloseDrawer();
          setListingAgainData();
        }}
        footer={null}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        destroyOnClose={true}
      >
        {modal === NFTModalState.MINT && <MintNFT />}
        {[NFTModalState.LIST, NFTModalState.LIST_AGAIN].includes(modal) && <Listing />}
      </Modal>
      <ModalTransactionSteps />
    </>
  );
};

export default TableNFTContent;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import {
  HeaderDashboard,
  SidebarDash,
  HeaderCont,
  PreMintForm,
  TableNFT,
  TableCollections,
  CollectionForm,
  CollectionEditForm,
  CollectionImportForm,
  SearchbarCollections,
  SearchbarNFT,
  EditNFTForm,
  UsersForm,
  TableUsers,
  UsersEditForm,
  HowItWorks,
  TableSystemUsers,
  SystemUsersForm,
  SystemUsersEditForm,
  ReleaseFundsContent,
  RoyaltiesContent,
  TablePolls,
  PollsForm,
  LeaderboardsContent,
  TablePodcasts,
  PodcastForm,
  TableNotifications,
  NotificationForm,
  TablePosts,
} from '../index';

const Dashboard = () => {
  let { pathname } = useLocation();

  return (
    <Layout style={{ height: '100vh' /* , overflow: 'hidden' */ }}>
      <HeaderDashboard />
      <Layout>
        <SidebarDash />
        <Layout>
          {pathname === '/premint' ? (
            <>
              <HeaderCont title="Premint a new NFT" />
              <PreMintForm />
            </>
          ) : pathname.includes('/edit/nft/') ? (
            <>
              <HeaderCont title="Edit NFT" />
              <EditNFTForm />
            </>
          ) : pathname === '/list/nfts' || pathname === '/' ? (
            <>
              <HeaderCont title="List of available NFTs" />
              <SearchbarNFT />
              <TableNFT />
            </>
          ) : pathname === '/list/users' ? (
            <>
              <HeaderCont title="List of available users" />
              <TableUsers />
            </>
          ) : pathname === '/list/systemUsers' ? (
            <>
              <HeaderCont title="List of system users" />
              <TableSystemUsers />
            </>
          ) : pathname === '/list/collections' ? (
            <>
              <HeaderCont title="List of collections" />
              <SearchbarCollections />
              <TableCollections />
            </>
          ) : pathname.includes('/edit/collection/') ? (
            <>
              <HeaderCont title="Edit collection" />
              <CollectionEditForm />
            </>
          ) : pathname === '/add/collection' ? (
            <>
              <HeaderCont title="Create a new collection" />
              <CollectionForm />
            </>
          ) : pathname === '/import/collection' ? (
            <>
              <HeaderCont title="Import a collection" />
              <CollectionImportForm />
            </>
          ) : pathname === '/export/leaderboard' ? (
            <>
              <HeaderCont title="Export a leaderboard" />
              <LeaderboardsContent />
            </>
          ) : pathname === '/add/user' ? (
            <>
              <HeaderCont title="Add users" />
              <UsersForm />
            </>
          ) : pathname.includes('/edit/user/') ? (
            <>
              <HeaderCont title="Edit user" />
              <UsersEditForm />
            </>
          ) : pathname === '/add/systemUser' ? (
            <>
              <HeaderCont title="Add system user" />
              <SystemUsersForm />
            </>
          ) : pathname.includes('/edit/systemUser/') ? (
            <>
              <HeaderCont title="Edit system user" />
              <SystemUsersEditForm />
            </>
          ) : pathname.includes('/how-it-works') ? (
            <>
              <HeaderCont title="How it works" />
              <HowItWorks />
            </>
          ) : pathname.includes('/list/release-funds') ? (
            <>
              <HeaderCont title="Release funds" />
              <ReleaseFundsContent />
            </>
          ) : pathname.includes('/list/royalties') ? (
            <>
              <HeaderCont title="Royalties" />
              <RoyaltiesContent />
            </>
          ) : pathname === '/list/polls' ? (
            <>
              <HeaderCont title="List of polls" />
              <TablePolls />
            </>
          ) : pathname.includes('/edit/poll/') ? (
            <>
              <HeaderCont title="Edit poll" />
              <PollsForm />
            </>
          ) : pathname === '/add/poll' ? (
            <>
              <HeaderCont title="New poll" />
              <PollsForm />
            </>
          ) : pathname === '/list/podcasts' ? (
            <>
              <HeaderCont title="List of podcasts" />
              <TablePodcasts />
            </>
          ) : pathname.includes('/edit/podcast/') ? (
            <>
              <HeaderCont title="Edit podcast" />
              <PodcastForm />
            </>
          ) : pathname === '/add/podcast' ? (
            <>
              <HeaderCont title="New podcast" />
              <PodcastForm />
            </>
          ) : pathname === '/list/notifications' ? (
            <>
              <HeaderCont title="List of notifications" />
              <TableNotifications />
            </>
          ) : pathname.includes('/edit/notification/') ? (
            <>
              <HeaderCont title="Edit notification" />
              <NotificationForm />
            </>
          ) : pathname === '/add/notification' ? (
            <>
              <HeaderCont title="New notification" />
              <NotificationForm />
            </>
          ) : pathname === '/list/posts' ? (
            <>
              <HeaderCont title="List of posts" />
              <TablePosts />
            </>
          ) : null}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

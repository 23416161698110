import React from 'react';
import OnboardProvider from './context/OnboardContext';
import BackofficeProvider from './context/BackofficeContext';
import Routes from './../src/components/login/routes/index';
import './App.css';

function App() {
	return (
		<BackofficeProvider>
			<OnboardProvider>
				<Routes />
			</OnboardProvider>
		</BackofficeProvider>
	);
}

export default App;

import React from 'react';
import {Link} from 'react-router-dom';
import {useAddress} from '../../context/OnboardContext';
import {Layout, Typography} from 'antd';
import {ControlOutlined} from '@ant-design/icons';

const {Header} = Layout;

const HeaderDash = () => {
	const walletAddress = useAddress();
	let user = JSON.parse(localStorage.getItem('user'));

	return (
		<Header
			className='header'
			style={{
				paddingLeft: 15,
				paddingRight: 15,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
		>
			<Link
				to='/'
				style={{
					margin: '0 auto 0 0',
					display: 'flex',
					color: 'white',
					alignItems: 'center',
				}}
			>
				<ControlOutlined style={{fontSize: '32px', marginRight: '10px'}} />
				<Typography.Title
					level={3}
					style={{color: 'currentColor', margin: '0'}}
				>
					WTD Marketplace
				</Typography.Title>
			</Link>

			{user && (
				<div
					style={{
						margin: '0 25px 0 auto',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							color: 'white',
							fontWeight: '600',
							lineHeight: '0',
							marginBottom: '22px',
						}}
					>
						{user.username.charAt(0).toUpperCase() + user.username.slice(1)}
					</span>
					<span
						style={{
							color: '#6b7185',
							lineHeight: '0',
						}}
					>
						{user.email}
					</span>
				</div>
			)}

			{walletAddress ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							color: 'white',
							fontWeight: '600',
							lineHeight: '0',
							marginBottom: '22px',
						}}
					>
						Connected to:{' '}
					</span>
					<span
						style={{
							color: '#6b7185',
							lineHeight: '0',
						}}
					>
						{walletAddress?.slice(0, 6) + '...' + walletAddress?.slice(-5)}
					</span>
				</div>
			) : null}
		</Header>
	);
};

export default HeaderDash;

const data = {
  local: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    marketplaceApiURL: 'http://localhost:4000',
    networkID: 43113,
    networkScan: 'https://testnet.snowtrace.io/',
  },
  development: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    marketplaceApiURL: 'https://space-junk-api-dev.waytoodigital.com',
    networkID: 43113,
    networkScan: 'https://testnet.snowtrace.io/',
  },
  staging: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    marketplaceApiURL: 'https://space-junk-api-stg.waytoodigital.com',
    networkID: 43113,
    networkScan: 'https://testnet.snowtrace.io/',
  },
  production: {
    marketplaceContractAddress: '0x0000000000000000000000000000000000000000',
    marketplaceApiURL: 'https://api.spacejunkies.xyz',
    networkID: 43114,
    networkScan: 'https://snowtrace.io/',
  },
};

export const CUSTOM_NODE_ENV = process.env.REACT_APP_CUSTOM_NODE_ENV;
export const ONBOARD_API_KEY = process.env.REACT_APP_ONBOARD_API_KEY;
export const FORMATIC_API_KEY = process.env.REACT_APP_FORMATIC_API_KEY;

export const MARKETPLACE_CONTRACT_ADDRESS = data[CUSTOM_NODE_ENV].marketplaceContractAddress;
export const MARKETPLACE_API_URL = data[CUSTOM_NODE_ENV].marketplaceApiURL;
export const NETWORK_ID = data[CUSTOM_NODE_ENV].networkID;
export const NETWORK_SCAN = data[CUSTOM_NODE_ENV].networkScan;
export const NETWORK_SCAN_NAME = 'Snowtrace';

export const MIN_PERCENT_INCREMENT = process.env.REACT_APP_MIN_PERCENT_INCREMENT || 1;
export const MAX_PERCENT_TO_PAYOUT = process.env.REACT_APP_MAX_PERCENT_TO_PAYOUT || 20;

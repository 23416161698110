import onboard from 'bnc-onboard';
import { FORMATIC_API_KEY, ONBOARD_API_KEY, NETWORK_ID } from '../config/index';

export function initOnboard(subscriptions) {
  return onboard({
    dappId: ONBOARD_API_KEY,
    hideBranding: true,
    networkId: parseInt(NETWORK_ID),
    darkMode: false,
    subscriptions,
    walletSelect: {
      heading: 'Connect your Wallet',
      description: 'Please select a wallet',
      explanation: `
        Crypto wallets store your private keys, keeping your crypto safe and accessible. They also allow you to send, receive, and spend cryptocurrencies like Bitcoin and Ethereum.<br /><br />
        If you're a begginer we recommend <a href="https://blog.coincodecap.com/fortmatic-wallet-review#What_is_Fortmatic" target="_blank" rel="noreferrer noopener">Fortmatic</a>.<br />
        If you're experienced we recommend <a href="https://metamask.io/" target="_blank" rel="noreferrer noopener">MetaMask</a>.`,
      wallets: [
        {
          walletName: 'detectedwallet',
          display: {
            desktop: false,
            mobile: false,
          },
        },
        {
          walletName: 'metamask',
        },
        {
          walletName: 'fortmatic',
          apiKey: FORMATIC_API_KEY,
        },
      ],
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
    ],
  });
}

import { Button, Col, Input, message, Row } from 'antd';
import { Form } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import { useAddress } from '../../context/OnboardContext';
import { DEFAULT_ROYALTY_PERCENTAGE_BIPS } from '../../utils/default.utils.constants';
import { MARKETPLACE_CONTRACT_ADDRESS } from '../../config';
import mintingContractABI from '../../services/web3/abis/ERC721.json';
import Web3 from 'web3';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomModalTransactionsStep from '../nfts/modalTransactionSteps/CustomModalTransactionSteps.component';
import { useHistory } from 'react-router-dom';
const web3 = new Web3(Web3.givenProvider);

const RoyaltiesContent = () => {
  const router = useHistory();
  const [form] = Form.useForm();
  const [defaultRoyaltyPercentage, setDefaultRoyaltyPercentage] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [stepBlockchainTx, setStepBlockchainTx] = useState(0);
  const [mintingContract, setMintingContract] = useState();
  const [userAcceptedTXHash, setUserAcceptedTXHash] = useState();
  const { collections } = useSelector(({ collections }) => {
    return collections;
  });
  const walletAddress = useAddress();

  useEffect(() => {
    if (!collections[0]?.contractAddress) {
      router.push('/');
    } else {
      setMintingContract(
        new web3.eth.Contract(
          mintingContractABI,
          collections[0]?.contractAddress
        )
      );
    }
  }, []);

  const getDefaultRoyaltiesVal = async () => {
    if (!mintingContract) return;
    const royaltyInfoMethod = await mintingContract.methods?.royaltyInfo;
    let royaltyInfo;
    royaltyInfoMethod &&
      (royaltyInfo = await mintingContract.methods
        ?.royaltyInfo(1, 10000)
        .call());
    setDefaultRoyaltyPercentage(
      (Object.values(royaltyInfo)[1] || DEFAULT_ROYALTY_PERCENTAGE_BIPS) / 100
    );
  };
  useEffect(() => {
    getDefaultRoyaltiesVal();
  }, [mintingContract]);

  const editRoyalties = async ({ newPercentage }) => {
    try {
      setModalVisible(true);
      setStepBlockchainTx(0);
      setUserAcceptedTXHash();
      collections.forEach(async ({ contractAddress }) => {
        const localMintingContract = new web3.eth.Contract(
          mintingContractABI,
          contractAddress
        );
        //TODO: we have to handle modal steps status for many collections, as one general modal status
        let _userAcceptedTXHash;
        await localMintingContract.methods
          .setRoyalties(MARKETPLACE_CONTRACT_ADDRESS, newPercentage)
          .send({
            from: walletAddress,
          })
          .once('transactionHash', function (hash) {
            setStepBlockchainTx(1);
            setUserAcceptedTXHash(hash);
            _userAcceptedTXHash = hash;
          })
          .once('receipt', function (receipt) {
            setStepBlockchainTx(2);
            setModalVisible(false);
            message.success('Royalties successfully set up', 3.5);
          })
          .on('error', function (error) {
            setModalVisible(false);
            setStepBlockchainTx();
            setUserAcceptedTXHash();
            error.message.includes('MetaMask') &&
              message.error(error.message, 3.5);
            !error.message.includes('MetaMask') &&
              message.error(
                <a
                  href={`https://goerli.etherscan.io/tx/${_userAcceptedTXHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View error on etherscan
                </a>,
                5
              );
          });
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const submitForm = async ({ percentage }) => {
    if (!percentage) return;
    await editRoyalties({
      newPercentage: Math.ceil(percentage * 100),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return defaultRoyaltyPercentage ? (
    <>
      <Content
        className="site-layout-background"
        style={{
          overflow: 'auto',
          padding: 20,
          margin: 20,
          minHeight: 280,
          backgroundColor: 'White',
        }}
      >
        <Text>
          Determine the percentage that should be received as royalties earned
          from secondary sales of the assets.
        </Text>

        <Form
          layout={'vertical'}
          form={form}
          initialValues={{
            percentage: defaultRoyaltyPercentage,
          }}
          style={{ width: '80%', marginTop: '2rem' }}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item
                label={`Percentage`}
                name="percentage"
                style={{}}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input type="number" min={0} max={100} step="0.1" suffix="%" />
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            style={{ marginTop: '1rem' }}
            htmlType="submit"
            disabled={stepBlockchainTx === 1}
          >
            Update royalty settings
          </Button>
        </Form>
      </Content>
      <CustomModalTransactionsStep
        visible={modalVisible}
        stepBlockchainTx={stepBlockchainTx}
        userAcceptedTXHash={userAcceptedTXHash}
        footer={
          stepBlockchainTx === 1 && [
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Wait and close modal
            </Button>,
          ]
        }
      />
    </>
  ) : null;
};

export default RoyaltiesContent;

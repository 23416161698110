import {useState, useEffect, createContext, useContext} from 'react';
import axios from 'axios';
import {MARKETPLACE_API_URL} from './../config/index';

let context = createContext();
let {Provider} = context;

export const useAuthentication = () => useContext(context);

export default function AuthenticationProvider({children}) {
	const [auth, setAuth] = useState(undefined);

	useEffect(() => {
		async function checkToken() {
			try {
				const res = await axios.get(`${MARKETPLACE_API_URL}/checkToken`, {
					withCredentials: true,
				});

				if (res.status === 200) {
					setAuth(true);
				}
			} catch (err) {
				setAuth(false);
				await axios.get(`${MARKETPLACE_API_URL}/logout`, {
					withCredentials: true,
				});
			}
		}
		checkToken();
	}, [auth]);

	return (
		<Provider
			value={{
				auth,
				setAuth,
			}}
		>
			{children}
		</Provider>
	);
}

import { Link } from 'react-router-dom';
import { Table, Button, Popconfirm, Progress, Spin, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPolls, removePoll, cleanStore } from '../../../redux/actions/polls';
import moment from 'moment';

const { Column } = Table;

const TablePolls = () => {
  const dispatch = useDispatch();
  const [polls, setPolls] = useState();
  const { polls: pollsData, pollDeleted, error, loadingPolls } = useSelector(({ polls }) => polls);

  useEffect(() => {
    setPolls(JSON.parse(JSON.stringify(pollsData)));
  }, [pollsData]);

  useEffect(() => {
    if (pollDeleted) {
      dispatch(getPolls());
    }
  }, [pollDeleted, dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: error.replace(/<\/?[^>]+(>|$)/g, ''),
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getPolls());
  }, [dispatch]);

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <h2 style={{ margin: '0' }}>List of polls</h2>

        <Link to="/add/poll" style={{ marginLeft: 'auto' }}>
          <Button type="primary" style={{ display: 'flex', alignItems: 'center' }}>
            + Create a new poll
          </Button>
        </Link>
      </div>
    );
  }

  function handleRemove(record) {
    dispatch(removePoll(record._id));
  }

  function expandedRowRender(data) {
    const columns = [
      { title: 'Question', dataIndex: 'question', key: 'question' },
      {
        title: 'Options',
        dataIndex: 'options',
        key: 'options',
        render: options => {
          const totalVotes = options.reduce((acc, value) => acc + value.votes, 0);
          return options.map((option, index) => (
            <div key={option._id}>
              {option.answer}: {option.votes} vote(s){' '}
              <Progress percent={Math.round((option.votes / totalVotes) * 100)} size="small" />
            </div>
          ));
        },
      },
    ];
    let rows = [];
    for (const item of data.questionnaire) {
      rows.push({
        key: item._id,
        question: item.question,
        options: item.options,
      });
    }
    return <Table columns={columns} dataSource={rows} pagination={false} />;
  }

  return (
    <>
      {loadingPolls ? (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      ) : (
        <Table
          dataSource={polls}
          style={{ margin: 15 }}
          size="small"
          title={() => header()}
          rowKey={row => row._id}
          expandable={{ expandedRowRender, defaultExpandedRowKeys: [0] }}
          pagination={false}
        >
          <Column title="ID" dataIndex="_id" key="id" />
          <Column
            title="Title"
            dataIndex="title"
            key="title"
            defaultSortOrder="ascend"
            sorter={(a, b) => a?.title?.localeCompare(b?.title)}
            width={250}
          />
          <Column
            title="Date of Creation"
            dataIndex="createdAt"
            key="date"
            defaultSortOrder="ascend"
            sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            width={180}
            render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
          />
          <Column
            title="End Date"
            dataIndex="endDate"
            key="enddate"
            sorter={(a, b) => new Date(a.endDate) - new Date(b.endDate)}
            width={180}
            render={date => <>{date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-'}</>}
          />
          <Column
            title="Action"
            key="actions"
            dataIndex="actions"
            width={120}
            render={(actions, record) => (
              <span>
                <Link to={`/edit/poll/${record._id}`} style={{ margin: '0 10px 0 0', padding: '0' }} type="link">
                  Edit
                </Link>
                <Popconfirm
                  title="Are you sure to delete this poll?"
                  onConfirm={() => handleRemove(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                    Remove
                  </Button>
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      )}
    </>
  );
};

export default TablePolls;

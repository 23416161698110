import axios from 'axios';
import {ActionTypes} from '../../utils/constants';
import {MARKETPLACE_API_URL} from '../../config';
import {message} from 'antd';

/* Add user to DB */
export const addUser = (data) => {
	const url = `${MARKETPLACE_API_URL}/user`;

	return async (dispatch) => {
		dispatch(addUserRequest());
		try {
			const res = await axios.post(url, data);
			return dispatch(addUserSuccess(res));
		} catch (err) {
			return dispatch(addUserFailure(err?.response));
		}
	};
};
const addUserRequest = () => {
	return {
		type: ActionTypes.ADD_USER_REQUEST,
	};
};
const addUserSuccess = (payload) => {
	return {
		type: ActionTypes.ADD_USER_SUCCESS,
		payload,
	};
};
const addUserFailure = (payload) => {
	return {
		type: ActionTypes.ADD_USER_FAILURE,
		payload,
	};
};

/* Remove user from DB*/
export const removeUser = (publicAddress) => {
	const url = `${MARKETPLACE_API_URL}/user/${publicAddress}`;

	return async (dispatch) => {
		dispatch(removeUserRequest());
		try {
			const res = await axios.delete(url);
			return dispatch(removeUserSuccess(res));
		} catch (err) {
			return dispatch(removeUserFailure(err?.response));
		}
	};
};
const removeUserRequest = () => {
	return {
		type: ActionTypes.REMOVE_USER_REQUEST,
	};
};
const removeUserSuccess = (payload) => {
	return {
		type: ActionTypes.REMOVE_USER_SUCCESS,
		payload,
	};
};
const removeUserFailure = (payload) => {
	return {
		type: ActionTypes.REMOVE_USER_FAILURE,
		payload,
	};
};

/* Get all users from DB */
export const getUsers = (page, pageSize, user) => {
	return async (dispatch) => {
		dispatch(getUsersRequest());
		try {
			const {data} = await axios.get(
				`${MARKETPLACE_API_URL}/users?currentPage=${page}&pageSize=${pageSize}&walletAddress=${user}`
			);
			dispatch(setUsersMetadata(data.metadata[0]));
			return dispatch(getUsersSuccess(data.data));
		} catch (err) {
			message.error(`${err.response.data.message}`, 2);
			return dispatch(getUsersFailure());
		}
	};
};
const getUsersRequest = () => {
	return {
		type: ActionTypes.GET_ALL_USERS_REQUEST,
	};
};
const getUsersSuccess = (payload) => {
	return {
		type: ActionTypes.GET_ALL_USERS_SUCCESS,
		payload,
	};
};
const getUsersFailure = () => {
	return {
		type: ActionTypes.GET_ALL_USERS_FAILURE,
	};
};
const setUsersMetadata = (payload) => {
	return {
		type: ActionTypes.SET_USERS_METADATA,
		payload,
	};
};

/* Edit user from DB*/
export const editUser = (publicAddress, data) => {
	const url = `${MARKETPLACE_API_URL}/user/${publicAddress}`;

	return async (dispatch) => {
		dispatch(editUserequest());
		try {
			const res = await axios.put(url, {data});
			return dispatch(editUserSuccess(res));
		} catch (err) {
			return dispatch(editUserFailure(err?.response));
		}
	};
};
const editUserequest = () => {
	return {
		type: ActionTypes.EDIT_USER_REQUEST,
	};
};
const editUserSuccess = (payload) => {
	return {
		type: ActionTypes.EDIT_USER_SUCCESS,
		payload,
	};
};
const editUserFailure = (payload) => {
	return {
		type: ActionTypes.EDIT_USER_FAILURE,
		payload,
	};
};

/* Clean user store */
export const cleanStore = () => {
	return {
		type: ActionTypes.CLEAN_STORE,
	};
};

import axios from 'axios';
import {ActionTypes} from '../../utils/constants';
import {MARKETPLACE_API_URL} from '../../config';
import {message} from 'antd';

/* Add system user to DB */
export const addSystemUser = (data) => {
	const url = `${MARKETPLACE_API_URL}/system/user`;

	return async (dispatch) => {
		dispatch(addSystemUserRequest());
		try {
			const res = await axios.post(url, data);
			return dispatch(addSystemUserSuccess(res));
		} catch (err) {
			return dispatch(addSystemUserFailure(err?.response));
		}
	};
};
const addSystemUserRequest = () => {
	return {
		type: ActionTypes.ADD_SYSTEM_USER_REQUEST,
	};
};
const addSystemUserSuccess = (payload) => {
	return {
		type: ActionTypes.ADD_SYSTEM_USER_SUCCESS,
		payload,
	};
};
const addSystemUserFailure = (payload) => {
	return {
		type: ActionTypes.ADD_SYSTEM_USER_FAILURE,
		payload,
	};
};

/* Remove system user from DB*/
export const removeSystemUser = (publicAddress) => {
	const url = `${MARKETPLACE_API_URL}/system/user/${publicAddress}`;

	return async (dispatch) => {
		dispatch(removeSystemUserRequest());
		try {
			const res = await axios.delete(url);
			return dispatch(removeSystemUserSuccess(res));
		} catch (err) {
			return dispatch(removeSystemUserFailure(err?.response));
		}
	};
};
const removeSystemUserRequest = () => {
	return {
		type: ActionTypes.REMOVE_SYSTEM_USER_REQUEST,
	};
};
const removeSystemUserSuccess = (payload) => {
	return {
		type: ActionTypes.REMOVE_SYSTEM_USER_SUCCESS,
		payload,
	};
};
const removeSystemUserFailure = (payload) => {
	return {
		type: ActionTypes.REMOVE_SYSTEM_USER_FAILURE,
		payload,
	};
};

/* Get users from DB */
export const getSystemUsers = (page, pageSize, user) => {
	return async (dispatch) => {
		dispatch(getSystemUsersRequest());
		try {
			const {data} = await axios.get(
				`${MARKETPLACE_API_URL}/system/users?currentPage=${page}&pageSize=${pageSize}&walletAddress=${user}`
			);

			dispatch(setSystemUsersMetadata(data.metadata[0]));
			return dispatch(getSystemUsersSuccess(data.data));
		} catch (err) {
			message.error(`${err.response.data.message}`, 2);
			return dispatch(getSystemUsersFailure());
		}
	};
};
const getSystemUsersRequest = () => {
	return {
		type: ActionTypes.GET_ALL_SYSTEM_USERS_REQUEST,
	};
};
const getSystemUsersSuccess = (payload) => {
	return {
		type: ActionTypes.GET_ALL_SYSTEM_USERS_SUCCESS,
		payload,
	};
};
const getSystemUsersFailure = () => {
	return {
		type: ActionTypes.GET_ALL_SYSTEM_USERS_FAILURE,
	};
};
const setSystemUsersMetadata = (payload) => {
	return {
		type: ActionTypes.SET_SYSTEM_USERS_METADATA,
		payload,
	};
};

/* Edit user from DB*/
export const editSystemUser = (publicAddress, data) => {
	const url = `${MARKETPLACE_API_URL}/system/user/${publicAddress}`;

	return async (dispatch) => {
		dispatch(editSystemUserequest());
		try {
			const res = await axios.put(url, {data});
			return dispatch(editSystemUserSuccess(res));
		} catch (err) {
			return dispatch(editSystemUserFailure(err?.response));
		}
	};
};
const editSystemUserequest = () => {
	return {
		type: ActionTypes.EDIT_SYSTEM_USER_REQUEST,
	};
};
const editSystemUserSuccess = (payload) => {
	return {
		type: ActionTypes.EDIT_SYSTEM_USER_SUCCESS,
		payload,
	};
};
const editSystemUserFailure = (payload) => {
	return {
		type: ActionTypes.EDIT_SYSTEM_USER_FAILURE,
		payload,
	};
};

/* Clean user store */
export const cleanStore = () => {
	return {
		type: ActionTypes.CLEAN_STORE,
	};
};

import axios from 'axios';
import { ActionTypes } from '../../utils/constants';
import { MARKETPLACE_API_URL } from '../../config';

const baseUrl = `${MARKETPLACE_API_URL}/podcast`;

export const getPodcasts = (page, pageSize) => {
  return async dispatch => {
    dispatch(getPodcastsRequest());
    try {
      const response = await axios.get(`${MARKETPLACE_API_URL}/podcasts/${page}/${pageSize}`);
      return dispatch(getPodcastsSuccess(response.data));
    } catch (err) {
      return dispatch(getPodcastsFailure(err?.response?.data));
    }
  };
};

export const getPodcast = id => {
  return async dispatch => {
    dispatch(getPodcastRequest());
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      return dispatch(getPodcastSuccess(response.data));
    } catch (err) {
      return dispatch(getPodcastFailure(err?.response?.data));
    }
  };
};

export const editPodcast = (id, podcastData) => {
  return async dispatch => {
    dispatch(editPodcastRequest());
    try {
      const response = await axios.put(`${baseUrl}/${id}`, podcastData);
      return dispatch(editPodcastSuccess(response.data));
    } catch (err) {
      return dispatch(editPodcastFailure(err?.response?.data));
    }
  };
};

export const addPodcast = podcastData => {
  return async dispatch => {
    dispatch(addPodcastRequest());
    try {
      const response = await axios.post(baseUrl, podcastData);
      return dispatch(addPodcastSuccess(response.data));
    } catch (err) {
      return dispatch(addPodcastFailure(err?.response?.data));
    }
  };
};

export const removePodcast = id => {
  return async dispatch => {
    dispatch(removePodcastRequest());
    try {
      const response = await axios.delete(`${baseUrl}/${id}`);
      return dispatch(removePodcastSuccess(response.data));
    } catch (err) {
      return dispatch(removePodcastFailure(err?.response?.data));
    }
  };
};

const getPodcastsRequest = () => {
  return {
    type: ActionTypes.GET_PODCASTS_REQUEST,
  };
};

const getPodcastsSuccess = payload => {
  return {
    type: ActionTypes.GET_PODCASTS_SUCCESS,
    payload,
  };
};

const getPodcastsFailure = payload => {
  return {
    type: ActionTypes.GET_PODCASTS_FAILURE,
    payload,
  };
};

const getPodcastRequest = () => {
  return {
    type: ActionTypes.GET_PODCAST_REQUEST,
  };
};

const getPodcastSuccess = payload => {
  return {
    type: ActionTypes.GET_PODCAST_SUCCESS,
    payload,
  };
};

const getPodcastFailure = payload => {
  return {
    type: ActionTypes.GET_PODCAST_FAILURE,
    payload,
  };
};

const editPodcastRequest = () => {
  return {
    type: ActionTypes.UPDATE_PODCAST_REQUEST,
  };
};

const editPodcastSuccess = payload => {
  return {
    type: ActionTypes.UPDATE_PODCAST_SUCCESS,
    payload,
  };
};

const editPodcastFailure = payload => {
  return {
    type: ActionTypes.UPDATE_PODCAST_FAILURE,
    payload,
  };
};

const addPodcastRequest = () => {
  return {
    type: ActionTypes.ADD_PODCAST_REQUEST,
  };
};

const addPodcastSuccess = payload => {
  return {
    type: ActionTypes.ADD_PODCAST_SUCCESS,
    payload,
  };
};

const addPodcastFailure = payload => {
  return {
    type: ActionTypes.ADD_PODCAST_FAILURE,
    payload,
  };
};

const removePodcastRequest = () => {
  return {
    type: ActionTypes.REMOVE_PODCAST_REQUEST,
  };
};

const removePodcastSuccess = payload => {
  return {
    type: ActionTypes.REMOVE_PODCAST_SUCCESS,
    payload,
  };
};

const removePodcastFailure = payload => {
  return {
    type: ActionTypes.REMOVE_PODCAST_FAILURE,
    payload,
  };
};

/* Clean store */
export const cleanStore = () => {
  return {
    type: ActionTypes.CLEAN_STORE,
  };
};

import React from 'react';
import {Button} from 'antd';
import {useOnboardContext, useAddress} from '../../context/OnboardContext.js';

const ConnectWalletBtn = ({text}) => {
	const {linkWallet} = useOnboardContext();
	const walletAddress = useAddress();

	return (
		<>
			{walletAddress ? (
				<Button type='primary' htmlType='submit'>
					{text}
				</Button>
			) : (
				<Button type='primary' onClick={linkWallet} style={{cursor: 'pointer'}}>
					Connect your wallet!
				</Button>
			)}
		</>
	);
};

export default ConnectWalletBtn;

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Table, Button, message, Popconfirm, Typography} from 'antd';
import {getUsers, removeUser, cleanStore} from '../../../redux/actions/users';
import 'antd/dist/antd.css';
import SearchbarUser from '../searchbarUsers/SearchbarUser';
import moment from 'moment';

const {Column} = Table;

const UsersTable = ({data}) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [user, setUser] = useState(undefined);
	const {users, loadingRemoveUser, removedUser, metadata} = useSelector(
		({users}) => users
	);

	useEffect(() => {
		dispatch(getUsers(page, pageSize, user));
		dispatch(cleanStore());
		/* eslint-disable */
	}, [page, pageSize, user, removedUser]);
	/* eslint-enable */

	useEffect(() => {
		loadingRemoveUser && message.loading('Removing user...', 0.5);
		removedUser?.status === 200 && message.success(removedUser.data.message, 2);
		removedUser?.status === 403 &&
			message.error(`${removedUser?.data?.message}`, 2);
	}, [loadingRemoveUser, removedUser]);

	const handleRemove = (record) => {
		dispatch(removeUser(record.publicAddress));
	};

	function showTotal(total) {
		return `Total ${total} items`;
	}

	function onShowSizeChange(page, pageSize) {
		setPage(page);
		setPageSize(pageSize);
	}

	function header() {
		return (
			<div style={{display: 'flex', padding: '0'}}>
				<h2 style={{margin: '0'}}>List of available users</h2>

				<Link to='/add/user' style={{marginLeft: 'auto'}}>
					<Button
						type='primary'
						style={{display: 'flex', alignItems: 'center'}}
					>
						+ Create a new user
					</Button>
				</Link>
			</div>
		);
	}

	return (
		<>
			<SearchbarUser setUser={setUser} setPage={setPage} />
			<Table
				dataSource={users}
				style={{margin: 15}}
				size='small'
				title={() => header()}
				rowKey='_id'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '20', '30'],
					total: metadata && metadata.total,
					showTotal: showTotal,
					onChange: onShowSizeChange,
					current: metadata && metadata.page,
				}}
			>
				<Column
					title='Public Address'
					dataIndex='publicAddress'
					key='publicAddress'
					ellipsis={true}
					render={(text, record) => (
						<Typography.Paragraph copyable={{text}}>{` ${text?.slice(
							0,
							6
						)}...${text?.slice(-5)}`}</Typography.Paragraph>
					)}
				/>
				<Column title='Email' dataIndex='email' key='email' ellipsis={true} />

				<Column
					title='Username'
					dataIndex='username'
					key='username'
					ellipsis={true}
				/>

				<Column
					title='Date of Creation'
					dataIndex='creationDate'
					key='date'
					defaultSortOrder='ascend'
					sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
					render={(date) => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
				/>

				<Column
					title='Action'
					key='actions'
					dataIndex='actions'
					render={(actions, record) => (
						<span>
							<Link
								to={`/edit/user/${record.publicAddress}`}
								style={{margin: '0 10px 0 0', padding: '0'}}
								type='link'
							>
								Edit
							</Link>
							<Popconfirm
								title='Are you sure to delete this collection?'
								onConfirm={() => handleRemove(record)}
								okText='Yes'
								cancelText='No'
							>
								<Button
									type='link'
									style={{margin: '0 10px 0 0', padding: '0'}}
								>
									Remove
								</Button>
							</Popconfirm>
						</span>
					)}
				/>
			</Table>
		</>
	);
};

export default UsersTable;

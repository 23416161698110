import { Link } from 'react-router-dom';
import { Table, Button, Popconfirm, Progress, Spin, notification, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPosts, pinPodcast, removePost, removeComment, cleanStore } from '../../../redux/actions/posts';
import moment from 'moment';

const { Column } = Table;

const TablePosts = () => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState();
  const { posts: postsData, postDeleted, postAddedOrUpdated, error, loadingPosts } = useSelector(({ posts }) => posts);

  useEffect(() => {
    setPosts(JSON.parse(JSON.stringify(postsData)));
    console.log(posts);
  }, [postsData]);

  useEffect(() => {
    if (postDeleted || postAddedOrUpdated) {
      dispatch(getPosts());
    }
  }, [postDeleted, postAddedOrUpdated, dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      notification.error({
        message: error.replace(/<\/?[^>]+(>|$)/g, ''),
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  function header() {
    return (
      <div style={{ display: 'flex', padding: '0' }}>
        <h2 style={{ margin: '0' }}>List of posts</h2>
      </div>
    );
  }

  function handleRemovePost(record) {
    dispatch(removePost(record._id));
  }

  function handleRemoveComment(record) {
    dispatch(removeComment(record._id));
  }

  function handlePin(record) {
    dispatch(pinPodcast(record._id));
  }

  function expandedRowRender(data) {
    const columns = [
      { title: 'Comment', dataIndex: 'comment', key: 'comment' },
      { title: 'User', dataIndex: 'user', key: 'user' },
      {
        title: 'Replies',
        dataIndex: 'replies',
        key: 'replies',
        render: replies => {
          return replies?.map(reply => (
            <div key={reply._id}>
              {reply.user.publicAddress}: {reply.comment}
              <span>
                <Popconfirm
                  title="Are you sure to delete this comment?"
                  onConfirm={() => handleRemoveComment(reply)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 10px', padding: '0' }}>
                    <Tag color="red">Remove</Tag>
                  </Button>
                </Popconfirm>
              </span>
            </div>
          ));
        },
      },
      {
        title: 'Remove comment',
        key: 'action',
        dataIndex: 'action',
        render: action => {
          return (
            <span>
              <Popconfirm
                title="Are you sure to delete this comment?"
                onConfirm={() => handleRemoveComment(action)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                  <Tag color="red">Remove</Tag>
                </Button>
              </Popconfirm>
            </span>
          );
        },
      },
    ];
    let rows = [];
    for (const item of data.comments.comments) {
      rows.push({
        key: item._id,
        comment: item.comment,
        user: item.user,
        replies: item?.replies,
        action: item,
      });
    }
    return <Table columns={columns} dataSource={rows} pagination={false} />;
  }

  return (
    <>
      {loadingPosts ? (
        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
      ) : (
        <Table
          dataSource={posts}
          style={{ margin: 15 }}
          size="small"
          title={() => header()}
          rowKey={row => row._id}
          expandable={{ expandedRowRender, defaultExpandedRowKeys: [0] }}
          pagination={false}
        >
          <Column
            title="Title"
            dataIndex="title"
            key="title"
            defaultSortOrder="ascend"
            sorter={(a, b) => a?.title?.localeCompare(b?.title)}
            width={250}
          />
          <Column
            title="Date of Creation"
            dataIndex="creationDate"
            key="date"
            defaultSortOrder="ascend"
            sorter={(a, b) => new Date(a.creationDate) - new Date(b.creationDate)}
            width={180}
            render={date => <>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</>}
          />
          <Column
            title="Category"
            dataIndex="category"
            key="category"
            sorter={(a, b) => new Date(a.category) - new Date(b.category)}
            width={150}
          />
          <Column
            title="Creator"
            dataIndex="creator"
            key="creator"
            render={creator => creator.publicAddress}
            width={150}
          />
          <Column
            title="Pinned"
            dataIndex="pinned"
            key="pinned"
            render={pinned => <Tag color={pinned ? 'green' : 'red'}>{pinned ? 'Pinned' : 'Unpinned'}</Tag>}
            width={150}
          />
          <Column
            title="Action"
            key="actions"
            dataIndex="actions"
            width={120}
            render={(actions, record) => (
              <span>
                <Popconfirm
                  title={`Are you sure to  ${record.pinned ? 'unpin' : 'pin'} this post?`}
                  onConfirm={() => handlePin(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                    {record.pinned ? 'Unpin post' : 'Pin post'}
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure to delete this post?"
                  onConfirm={() => handleRemovePost(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ margin: '0 10px 0 0', padding: '0' }}>
                    Remove
                  </Button>
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      )}
    </>
  );
};

export default TablePosts;

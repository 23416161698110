import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Form, Button, message, Typography, Result } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { MARKETPLACE_API_URL } from '../../config';
import { useAuthentication } from './../../context/AuthApiContext';
import { useOnboardContext, useWalletProvider } from './../../context/OnboardContext';

axios.defaults.withCredentials = true;

const Signin = () => {
  const [allowLogin, setAllowLogin] = useState(false);
  const [noAccessModal, setNoAccessModal] = useState(false);
  const { setAuth } = useAuthentication();
  const { linkWallet, address, resetWallet } = useOnboardContext();
  const provider = useWalletProvider();
  const web3 = new Web3(provider);

  const onFinish = async values => {
    setAllowLogin(true);
    await linkWallet();
  };

  const signMessage = async (walletAddress, nonce) => {
    const message = `Please sign this message to give us permission to securely access your private user data in our database.\n\nThis request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will be reset after 24 hours.\n\nWallet address: ${walletAddress} \n\nNonce: ${nonce}`;
    const signature = await web3.eth.personal.sign(message, walletAddress.toLowerCase());

    return { message, signature };
  };

  useEffect(() => {
    if (address && allowLogin) {
      axios
        .post(`${MARKETPLACE_API_URL}/admin/login`, {
          walletAddress: address,
        })
        .then(async response => {
          const signature = await signMessage(address, response.data.nonce);
          return signature;
        })
        .then(response => {
          axios
            .post(
              `${MARKETPLACE_API_URL}/admin/login/sign`,
              {
                message: response.message,
                signature: response.signature,
              },
              {
                withCredentials: true,
              }
            )
            .then(async response => {
              if (response.status === 200) {
                message.success(`${response?.data?.message}`, 2.5);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                setAuth(true);
              }
            });
        })
        .catch(err => {
          (err?.response?.status === 404 || err?.response?.status === 401) &&
            message.error(`${err?.response?.data?.message}`, 2.5);
          resetWallet();
          setNoAccessModal(true);
        });
    }

    // eslint-disable-next-line
  }, [address, allowLogin]);

  return (
    <div
      className="site-card-border-less-wrapper"
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        style={{
          margin: 'auto',
          border: '1px solid #d9d9d9',
          padding: '20px',
          width: noAccessModal ? '500px' : '350px',
        }}
      >
        {!noAccessModal ? (
          <>
            <ControlOutlined
              style={{
                fontSize: '64px',
                margin: '0 auto 30px',
                display: 'block',
              }}
            />
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              WTD Marketplace
            </Typography.Title>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                Connect your account
              </Button>
            </Form.Item>
          </>
        ) : (
          <Result
            status="403"
            title=""
            subTitle={
              <>
                <p>It looks like you’re not in the allow list to test WTD’s NFT marketplace whitelabel solution. </p>
                <p>Check the following link and fill the form to gain access.</p>
              </>
            }
            extra={
              <Button
                href="https://docs.google.com/forms/d/e/1FAIpQLSfw0Kn8pbgPc4HJpnpnkes_LLwb2__VWTLhWouHLvkC2Xv24g/viewform"
                target="_blank"
                without
                rel="noreferrer"
                type="primary"
                onClick={() => setNoAccessModal(false)}
              >
                I want access
              </Button>
            }
          />
        )}
      </Form>
    </div>
  );
};

export default Signin;

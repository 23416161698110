import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './on-board.css';

import store from './redux/store/index';

ReactDOM.render(
	<Provider store={store}>
		<CookiesProvider>
			<Router>
				<App />
			</Router>
		</CookiesProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {Route, Redirect} from 'react-router-dom';
import {useAuthentication} from './../../../../context/AuthApiContext';

const ProtectedLogin = ({component: Component, ...rest}) => {
	const {auth} = useAuthentication();

	return (
		// CURRENT: Checking if value is boolean to see if it was set (fetched) using the API and afterwards either allow access or return an error
		// TODO: See if we can do it a bit more clean removing the typeof check and awaiting for the value to be properly defined
		typeof auth == 'boolean' && (
			<Route
				{...rest}
				render={() => (!auth ? <Component /> : <Redirect to='/' />)}
			/>
		)
	);
};

export default ProtectedLogin;

import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Divider, Button } from 'antd';
import {
  FormOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  TableOutlined,
  DollarCircleOutlined,
  WalletOutlined,
  ImportOutlined,
  ExportOutlined,
  UnorderedListOutlined,
  AudioOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { useAuthentication } from './../../context/AuthApiContext';
import { useOnboardContext } from './../../context/OnboardContext';
import { useBackoffice } from './../../context/BackofficeContext';

import { MARKETPLACE_API_URL } from '../../config';

const { Sider } = Layout;

const SidebarDash = () => {
  const { resetWallet } = useOnboardContext();
  const { setAuth } = useAuthentication();
  const { setTooltips } = useBackoffice();
  const history = useHistory();

  const logout = async () => {
    try {
      const res = await axios.get(`${MARKETPLACE_API_URL}/logout`, {
        withCredentials: true,
      });

      if (res.status === 200) {
        localStorage.removeItem('user');
        resetWallet();
        setAuth(false);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const howItWorks = async () => {
    await setTooltips(true);
    history.push('/list/collections');
  };

  return (
    <Sider width={200} className="site-layout-background ant-menu-inline">
      <Menu
        mode="inline"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRight: 0,
        }}
      >
        <Menu.Item className="btn-premint-nft" key="sub2" icon={<FormOutlined />} title="Mint NFT's">
          <Link to="/premint">Premint NFT</Link>
        </Menu.Item>
        <Menu.Item className="btn-import-collection" key="sub3" icon={<ImportOutlined />} title="Import Collection">
          <Link to="/import/collection">Import Collection</Link>
        </Menu.Item>
        <Divider orientation="center" style={{ display: 'flex', margin: '0 0 0 0' }}></Divider>
        <Menu.Item className="table-nfts" key="5" icon={<TableOutlined />}>
          <Link to="/list/nfts">NFTs</Link>
        </Menu.Item>
        <Menu.Item className="table-collections" key="6" icon={<TableOutlined />}>
          <Link to="/list/collections">Collections</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<TableOutlined />}>
          <Link to="/list/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<TableOutlined />}>
          <Link to="/list/systemUsers">System users</Link>
        </Menu.Item>
        <Menu.Item key="m12" icon={<UnorderedListOutlined />}>
          <Link to="/list/polls">Polls</Link>
        </Menu.Item>
        {/* {collections?.length > 0 && ( */}
        <Menu.Item key="10" icon={<DollarCircleOutlined />}>
          <Link to="/list/royalties">Royalties</Link>
        </Menu.Item>
        {/* )} */}

        <Menu.Item key="11" icon={<WalletOutlined />}>
          <Link to="/list/release-funds">Release funds</Link>
        </Menu.Item>
        {/* <Menu.Item className="btn-export-leaderboard" key="12" icon={<ExportOutlined />} title="Export Leaderboard">
          <Link to="/export/leaderboard">Export Leaderboard</Link>
        </Menu.Item> */}
        <Menu.Item key="12" icon={<AudioOutlined />}>
          <Link to="/list/podcasts">Podcasts</Link>
        </Menu.Item>
        <Menu.Item key="13" icon={<BellOutlined />}>
          <Link to="/list/notifications">Notifications</Link>
        </Menu.Item>

        <Menu.Item key="14" icon={<FormOutlined />}>
          <Link to="/list/posts">Posts</Link>
        </Menu.Item>
        <Divider style={{ display: 'flex', margin: '0 0 auto 0' }} />

        <Divider style={{ display: 'flex', margin: 'auto 0 0 0' }} />
        <Menu.Item key="9">
          <Button type="link" icon={<QuestionCircleOutlined />} onClick={() => howItWorks()}>
            How it works
          </Button>
        </Menu.Item>
        <Divider style={{ display: 'flex', margin: '0 0 0 0' }} />
        <Menu.Item key="sub4">
          <Button type="link" danger icon={<LogoutOutlined />} onClick={() => logout()}>
            Log out
          </Button>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SidebarDash;

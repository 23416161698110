import { combineReducers } from 'redux';
import nfts from './nfts';
import users from './users';
import systemUsers from './systemUsers';
import collections from './collections';
import polls from './polls';
import podcasts from './podcasts';
import notifications from './notifications';
import posts from './posts';

export default combineReducers({
  nfts,
  users,
  systemUsers,
  collections,
  polls,
  podcasts,
  notifications,
  posts,
});

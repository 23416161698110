import React from 'react';
import {Switch} from 'react-router-dom';
import AuthenticationProvider from './../../../context/AuthApiContext';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ProtectedLogin from './ProtectedLogin/ProtectedLogin';
import {Signin, Dashboard} from './../../../components';

const Routes = () => {
	return (
		<Switch>
			<AuthenticationProvider>
				<ProtectedLogin exact path='/signin' component={Signin} />
				<PrivateRoute
					path={[
						'/',
						'/premint',
						'/mint',
						'/list',
						'/listing',
						'/add',
						'/edit',
						'/create',
					]}
					component={Dashboard}
				/>
			</AuthenticationProvider>
		</Switch>
	);
};

export default Routes;
